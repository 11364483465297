<!--
either listen for the confirm/cancel events, or wait for the resolving promise returned by open()
-->

<template>
	<v-dialog v-model="dialog" :max-width="maxWidth" v-bind="$attrs">
		<template slot="activator" slot-scope="scope"><slot name="activator" v-bind="scope" /></template>
		<v-card>
			<v-card-title class="headline">
				{{title}}
			</v-card-title>
			<v-card-text>
				<slot></slot>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn :color="colorCancel" text @click="onCancel">
					<v-icon left v-if="iconCancel && iconCancel!=''">{{iconCancel}}</v-icon>
					{{labelCancel}}
				</v-btn>
				<v-btn v-if="labelConfirm" :color="colorConfirm" @click="onConfirm" class="white--text">
					<v-icon left v-if="iconConfirm && iconConfirm!=''">{{iconConfirm}}</v-icon>
					{{labelConfirm}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>

import close from './dialog_close_mixin';

export default {
	mixins: [
		close(['dialog']),
	],
	data: ()=>({
		dialog: false,
		promise: null,
		resultHandled: false,
	}),
	props: {
		title: {
			type: String,
			default: "Потвърждение",
		},
		maxWidth: {
			type: Number,
			default: 400,
		},
		iconConfirm: String,
		iconCancel: String,
		labelConfirm: {
			type: String,
			default: "Потвърди",
		},
		labelCancel: {
			type: String,
			default: "Отмени",
		},
		colorConfirm: {
			type: String,
			default: "primary",
		},
		colorCancel: {
			type: String,
			default: "grey",
		},
	},
	watch: {
		dialog(to, from){
			if (!from && to) {
				// dialog opened
				this.resultHandled = false;
			}
			if (!to && from) {
				// dialog closed
				if (!this.resultHandled) {
					this.resolve(false);
				}
			}
		},
	},
	methods: {
		resolve(result){
			if (this.resultHandled) return;
			if (this.promise!==null) {
				this.promise.resolve(result);
				this.promise = null;
			}
			if (result) {
				/**
				 * @event confirm
				 */
				this.$emit('confirm');
			}
			else {
				/**
				 * @event cancel
				 */
				this.$emit('cancel');
			}
			this.resultHandled = true;
		},
		open(){
			return new Promise((resolve, reject)=>{
				if (this.promise!==null) {
					this.promise.resolve(false);
					this.promise = null;
				}
				this.promise = {resolve, reject};
				this.dialog = true;
			});
		},
		onConfirm(){
			this.resolve(true);
			this.dialog = false;
		},
		onCancel(){
			this.resolve(false);
			this.dialog = false;
		},
	},
}
</script>