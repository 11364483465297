// inspired by https://jessarcher.com/blog/closing-modals-with-the-back-button-in-a-vue-spa/

/**
 * save and restore a list of props when navigation goes back
 * needed to close a modal dialog with the back button
 */
export default function (props) {
  const initialValues = {};

  return {
    created () {
      for (const prop of props) {
        initialValues[prop] = this[prop];
      }

      const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
        for (const prop of props) {
          if (this[prop] !== initialValues[prop]) {
            this[prop] = initialValues[prop];
            next(false);
            return;
          }
        }

        next();
      });

      this.$once('hook:destroyed', () => {
        unregisterRouterGuard()
      });
    },
  }
}